.header {
  display: flex;
  justify-content: space-between;
}

.year {
  font-size: 30px;
  padding-left: 1rem;
  padding-top: 1rem;
  color: black;
  text-decoration: none;
}


.footer {
  color: #999;
  font-size: 14px;
  text-align: center;
  font-family: Courier,georgia;
  padding: 1rem;
}

.login {
  height: 1.5rem;
  width: 1.5rem;
  padding-right: 1rem;
  padding-top:1rem;
  cursor: pointer;
}
