.sliderContainer {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.currentWeek {
    text-align: center;
    color: #a31e32;
    font-size: 22px;
}

.currentRange {
    font-size: 12px;
    color: #232323;
}

.prevButton, .nextButton {
    width: 20px;
}

.prevButton,.nextButton:hover {
    cursor: pointer;
}