@font-face {
  font-family: Canvas_Black;
  src: url("./Canvas_Black.otf") format("opentype");
}

@font-face {
  font-family: Canvas_Regular;
  src: url("./Canvas_Regular.otf") format("opentype");
}

body, html { 
  font-family: 'Canvas_Black', sans-serif;
  background-color: #ebeae9;
}

