.timelocation {
   width: 150px;
   text-align: right;
   border-right: 2px solid #222;
   padding-bottom: 1rem;
   padding-top: 1rem;
}

.timestamp {
   color:  #9c9876;
   font-size: 14px;
}

.weekndate {
   display: flex;
   flex-direction: row;
   justify-content: end;
}

.date {
   margin-left: .5rem;
   font-size: 18px;
}

.week {
   font-size: 18px;
}

.location {
   color : #999;
   font-size: 12px;
}

.timestamp, .weekndate, .location {
   padding: 2px
}

.weekndate, .timestamp, .location {
   padding-right : .5rem;
}

