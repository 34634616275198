@font-face {
    font-family: Typewriter;
    src: url("../Typewriter.otf") format("opentype");
  }

.row {
    display: flex;
    position: relative
}
.content {
    padding: 1rem;
    width: 100%;
    text-wrap: wrap;
    line-height: 2;
    font-family: Typewriter;
}

.delete {
    position: absolute;
    right: 1rem;
    top: 0;
    cursor: pointer;
}

.audio {
    width: 240px
}