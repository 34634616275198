.validationButton {
    background: #a31e32;
    color: white;
    width: 8rem;
    padding: 1rem;
    font-size: 22px;
    cursor: pointer;
}

.validationInput {
    padding: 1rem;
    border: none;
    border-bottom: 2px solid black;
    background: transparent;
    outline: none;
    width: 8rem;
    font-size: 22px;
    font-family: 'Canvas_Black';
    caret-color: black;
}

.validationContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 8rem;
}

.wrongText {
    text-align: center;
    margin: 1rem;
    font-size: 18px;
}

.publishBtn {
    background: #a31e32;
    color: white;
    width: 4rem;
    padding: .5rem;
    font-size: 16px;
    cursor: pointer;
}

.publishContent {
    height: 12rem;
    box-sizing: border-box;
    border: 2px solid #ccc;
    background-color: #f8f8f8;
    resize: none;
    font-size: 16px;
    font-family: 'Typewriter';
    width: 100%;
}

textarea:focus, input:focus{
    outline: none;
}